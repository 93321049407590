import { Stack, Typography } from "@mui/material";
import { OutlinedButton } from "../../../global/buttons/material/outlinedButton/OutlinedButton";
import { RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { Add, Refresh } from "@mui/icons-material";
import { OnboardingInjuryItem } from "./OnboardingInjuryItem";
import {
  invertShowFrontInjuryImage,
  setShowAddInjuryModal,
} from "../../../../redux/onboardingSlice";
import { IconButton } from "../../../global/buttons/material/iconButton/IconButton";

export const OnboardingInjuriesList = () => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors,
  );
  const onboardingRedux = useSelector(
    (state: RootState) => state.onboardingSlice,
  );
  const dispatch = useDispatch();

  return (
    <Stack className="w-[325px]">
      <Stack direction={"row"} className="items-center justify-between ">
        {onboardingRedux.injuries.length > 0 ? (
          <Typography className="text-[20px] font-semibold">
            Injury List
          </Typography>
        ) : (
          <div />
        )}
        <div className="space-x-[10px]">
          <OutlinedButton
            title="Log Injury"
            startIcon={<Add />}
            onClick={() => dispatch(setShowAddInjuryModal(true))}
            borderColor={componentColors.outline.outline}
          />
          <IconButton
            icon={<Refresh sx={{ color: componentColors.primary.primary }} />}
            useBorder
            onClick={() => dispatch(invertShowFrontInjuryImage())}
          />
        </div>
      </Stack>
      <Stack className="h-[150px] lg:h-[300px] lg:overflow-y-scroll">
        {onboardingRedux.injuries.map((userInjury) => {
          return (
            <OnboardingInjuryItem
              key={userInjury.injuryId}
              injury={userInjury}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};
