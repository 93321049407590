import { Stack } from "@mui/material";
import { TextButton } from "../../../global/buttons/material/textButton/TextButton";
import { PrimaryButton } from "../../../global/buttons/material/primaryButton/PrimaryButton";
import { RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  decreaseCurrentStep,
  increaseCurrentStep,
  setIsEditing,
  setIsLoading,
  setIsOpenOnboardingModal,
  setShowCancelModal,
  setUserData,
} from "../../../../redux/onboardingSlice";
import { NavigateBefore } from "@mui/icons-material";
import { IOnboardingActionButtons } from "../../../../interfaces/onboarding/onboardingActionButtons/IOnboardingActionButtons";
import { useRouter } from "next/router";
import { OutlinedButton } from "../../../global/buttons/material/outlinedButton/OutlinedButton";

export const OnboardingActionButtons = ({
  currentAction,
  disablePrimaryButton = false,
}: IOnboardingActionButtons) => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors,
  );
  const onboardingRedux = useSelector(
    (state: RootState) => state.onboardingSlice,
  );
  const dispatch = useDispatch();
  const router = useRouter();

  return (
    <Stack className="flex flex-col-reverse lg:flex-row justify-between lg:items-center mt-[30px]">
      {onboardingRedux.step > 1 && !onboardingRedux.isEditing ? (
        <TextButton
          className="w-full lg:w-auto"
          text="Back"
          color={componentColors.surface.onSurface}
          onClick={() => {
            dispatch(decreaseCurrentStep());
          }}
          startIcon={<NavigateBefore className="hidden lg:block" />}
        />
      ) : (
        <div />
      )}

      <Stack direction={"row"} className="lg:space-x-[24px] items-center">
        {onboardingRedux.isEditing ? (
          <OutlinedButton
            title="Cancel"
            className="mr-2 lg:mr-0"
            onClick={() => {
              dispatch(setIsOpenOnboardingModal(false));
            }}
          />
        ) : (
          <>
            <TextButton
              text="Skip All"
              className="hidden lg:block"
              color={componentColors.surface.onSurface}
              onClick={() => {
                dispatch(setShowCancelModal(true));
              }}
            />
            <TextButton
              text="Skip"
              className="hidden lg:block"
              color={componentColors.surface.onSurface}
              onClick={() => {
                dispatch(increaseCurrentStep());
              }}
            />
          </>
        )}

        <PrimaryButton
          title={onboardingRedux.isEditing ? "Save Changes" : "Next"}
          className="py-[10px] px-[24px] w-full lg:w-auto"
          disabled={onboardingRedux.isLoading || disablePrimaryButton}
          onClick={async () => {
            if (currentAction) {
              dispatch(setIsLoading(true));
              const newUser = await currentAction();
              dispatch(setUserData(newUser));
            }

            if (onboardingRedux.isEditing) {
              dispatch(setIsEditing(false));
              dispatch(setIsOpenOnboardingModal(false));

              return;
            }

            onboardingRedux.step < 6
              ? dispatch(increaseCurrentStep())
              : dispatch(setIsOpenOnboardingModal(false));

            if (
              onboardingRedux.step === 6 &&
              router.asPath.includes("/onboarding")
            ) {
              void router.replace("/");
            }
          }}
        />
      </Stack>
    </Stack>
  );
};
