import { Autocomplete, TextField } from "@mui/material";
import React, { Attributes, useMemo } from "react";
import { IAutoCompleteInput } from "../../../../interfaces/globalComponents/inputs/autoCompleteInput/IAutoCompleteInput";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { FixedSizeList, ListChildComponentProps } from "react-window";

type ListBoxProps = {
  children: React.ReactElement[];
};

const ListBox = React.forwardRef<HTMLUListElement, ListBoxProps>(
  function ListBox(props: ListBoxProps, ref) {
    const { children, ...other } = props;
    const itemCount = Array.isArray(children) ? children.length : 0;
    const itemSize = 48;

    return (
      <ul
        ref={ref}
        style={{
          padding: 0,
          listStyleType: "none",
          overflowY: "hidden",
        }}
        {...other}
      >
        <FixedSizeList
          height={Math.min(10, itemCount) * itemSize}
          itemCount={itemCount}
          itemSize={itemSize}
          width={"200%"}
          className={"flex flex-col items-start"}
        >
          {({ index, style }: ListChildComponentProps) => {
            const item = children[index];
            const props = item.props as Partial<any> & Attributes;

            return (
              <li
                {...item.props}
                key={index}
                style={{ ...style, overflow: "hidden" }}
              >
                {React.cloneElement(item, props)}
              </li>
            );
          }}
        </FixedSizeList>
      </ul>
    );
  },
);

export const AutoCompleteInput = <T,>({
  label,
  options,
  onChange,
  value,
  placeholder,
  onClear,
  onInputChange,
  className,
  getOptionLabel,
  renderOption,
  isFetchingItems,
  clearOnBlur,
}: IAutoCompleteInput<T>) => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors,
  );
  const memoizedOptions = useMemo(() => options, [options]);

  return (
    <Autocomplete
      className={className}
      getOptionLabel={getOptionLabel}
      disablePortal
      ListboxComponent={ListBox}
      loading={isFetchingItems}
      id="auto-complete-app"
      onInputChange={onInputChange}
      clearOnBlur={clearOnBlur}
      onChange={(event, reason) => {
        if (!reason && onClear) {
          onClear();
          return;
        }
        onChange && onChange(event, reason);
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={placeholder} />
      )}
      renderOption={renderOption}
      options={memoizedOptions}
      value={value}
      sx={{
        "& fieldset": {
          borderColor: componentColors.outline.outline,
        },
        ".Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: componentColors.primary.primary,
        },
        "& button svg": {
          color: componentColors.surface.onSurface,
        },
        "& label.Mui-focused": {
          color: componentColors.primary.primary,
        },
        "& input": {
          color: componentColors.surface.onSurface,
        },
      }}
    />
  );
};
