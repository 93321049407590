import { useState } from "react";
import { DefaultModal } from "../../../global/modals/material/DefaultModal";
import { Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  BodyInjury,
  MuscleArea,
} from "../../../../interfaces/onboarding/injuries/BodyInjury";
import { addInjury, getInjuries, InjuryData } from "../../../../api/onboarding";
import useUser from "../../../../hooks/user/useUser";
import * as Sentry from "@sentry/nextjs";
import { DateInput } from "../../../global/inputs/dateInput/DateInput";
import { TextInput } from "../../../global/inputs/textInput/TextInput";
import { TextButton } from "../../../global/buttons/material/textButton/TextButton";
import { PrimaryButton } from "../../../global/buttons/material/primaryButton/PrimaryButton";
import {
  setShowAddInjuryModal,
  setUserInjuries,
} from "../../../../redux/onboardingSlice";
import { SelectInput } from "../../../global/inputs/selectInput/SelectInput";
import { useSnackbarContext } from "../../../global/snackbar/SnackbarProvider";
import { Snackbar } from "../../../global/snackbar";
import { getUser } from "../../../../api/user";

export const OnboardingInjuryModal = () => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors,
  );
  const onboardingRedux = useSelector(
    (state: RootState) => state.onboardingSlice,
  );
  const { user } = useUser();
  const [selectedBodyPart, setSelectedBodyPart] = useState<MuscleArea>(null);
  const [injuriesList, setInjuriesList] = useState<BodyInjury[]>([]);
  const [selectedInjury, setSelectedInjury] = useState<BodyInjury>(null);
  const [startDate, setStartDate] = useState<string>(null);
  const [endDate, setEndDate] = useState<string>(null);
  const [notes, setNotes] = useState<string>(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { setSnackbar } = useSnackbarContext();

  const handleAddNewInjury = async () => {
    try {
      const injuryData: InjuryData = {
        injuryId: selectedInjury.id,
        athleteId: user.id,
        notes: notes,
        startDate: startDate ? new Date(startDate).getTime() : null,
        endDate: endDate ? new Date(endDate).getTime() : null,
      };

      setIsLoading(true);
      await addInjury(user, injuryData);
      const updatedUser = await getUser(user);
      setNotes("");
      setStartDate(null);
      setEndDate(null);
      setInjuriesList([]);
      setSelectedBodyPart(null);
      setSelectedInjury(null);
      setSnackbar(<Snackbar message={"Injury Logged"} />);
      dispatch(setShowAddInjuryModal(false));
      dispatch(setUserInjuries(updatedUser.injuriesHistory));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <DefaultModal
      open={onboardingRedux.showAddInjuryModal}
      onClose={() => dispatch(setShowAddInjuryModal(false))}
    >
      <div className="lg:w-[678px] h-[50vh] lg:h-auto  overflow-y-scroll p-5 lg:scrollbar-hide">
        <Typography
          className="font-medium text-[28px]"
          sx={{
            color: componentColors.surface.onSurfaceDetachable,
          }}
        >
          Add an injury
        </Typography>

        <div className="grid lg:grid-cols-2 mx-auto gap-y-[40px] gap-x-[24px] mt-[24px]">
          <SelectInput
            placeholder="Select injured area"
            label="Body Part"
            defaultValue={selectedBodyPart?.name}
            options={onboardingRedux.bodyAreas.map((item) => {
              return { option: item.name, title: item.name };
            })}
            onChange={(event) => {
              setSelectedInjury(null);
              const currentSelectedBodyPart = onboardingRedux.bodyAreas.find(
                (item) => item.name === event.target.value,
              );

              setSelectedBodyPart(currentSelectedBodyPart);

              getInjuries(user, currentSelectedBodyPart.id)
                .then((list) => {
                  setInjuriesList(list);
                })
                .catch((error: Error) => {
                  Sentry.captureException({
                    category: "OnboardingInjuryModal",
                    message: "error on getInjuries",
                    data: error,
                  });
                });
            }}
          />

          <SelectInput
            label="Injury"
            placeholder="Select your injury name/type"
            defaultValue={selectedInjury?.title}
            options={injuriesList.map((item) => {
              return { option: item.title, title: item.title };
            })}
            onChange={(event) => {
              setSelectedInjury(
                injuriesList.find((item) => item.title === event.target.value),
              );
            }}
          />

          <DateInput
            label="Start date"
            value={startDate ? new Date(startDate) : null}
            onChange={(value) => {
              setStartDate(value?.toISOString());
            }}
            maxDate={new Date()}
          />

          <DateInput
            label="End date"
            value={endDate ? new Date(endDate) : null}
            onChange={(value) => {
              setEndDate(value?.toISOString());
            }}
            minDate={new Date(startDate)}
          />
        </div>
        <TextInput
          label="Injury notes"
          rows={5}
          placeholder="Injury notes"
          className="w-full mt-[40px] "
          onChange={(event) => setNotes(event.target.value)}
          value={notes}
        />

        <Stack direction={"row"} className="w-full justify-between mt-[40px]">
          <TextButton
            text="Back"
            onClick={() => dispatch(setShowAddInjuryModal(false))}
          />
          <PrimaryButton
            disabled={
              selectedInjury === null ||
              selectedBodyPart === null ||
              startDate === null ||
              isLoading
            }
            title="Record Injury"
            onClick={handleAddNewInjury}
          />
        </Stack>
      </div>
    </DefaultModal>
  );
};
