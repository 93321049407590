import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { Stack, Typography } from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";
import { IOnboardingInjuryItem } from "../../../../interfaces/onboarding/onboardingInjuryItem/IOnboardingInjuryItem";
import { deleteInjury } from "../../../../api/onboarding";
import { useSnackbarContext } from "../../../global/snackbar/SnackbarProvider";
import { ErrorSnackbar, Snackbar } from "../../../global/snackbar";
import { getUserData } from "../../../../lib/generic";
import { setUserInjuries } from "../../../../redux/onboardingSlice";
import useUser from "../../../../hooks/user/useUser";
import { AppIconButton } from "../../../global/ui/buttons";
import { useState } from "react";

export const OnboardingInjuryItem = ({ injury }: IOnboardingInjuryItem) => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors,
  );
  const onboardingRedux = useSelector(
    (state: RootState) => state.onboardingSlice,
  );
  const { setSnackbar } = useSnackbarContext();
  const { user } = useUser();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const deleteInjuryHandler = async () => {
    try {
      setLoading(true);
      await deleteInjury(onboardingRedux.userData, injury.id);
      const userUpdated = await getUserData(user);
      dispatch(setUserInjuries(userUpdated.injuriesHistory));
      setSnackbar(<Snackbar message={"Injury Deleted"} />);
    } catch (_) {
      setSnackbar(<ErrorSnackbar message={"Error while deleting snackbar"} />);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack
      sx={{
        borderBottom: "1px solid",
        borderColor: componentColors.surface.onSurfaceVariant,
      }}
      direction={"row"}
      className="py-[8px] px-2 justify-between"
    >
      <div>
        <Typography
          sx={{ color: componentColors.surface.onSurface }}
          className="text-[16px]"
        >
          {injury.injury.title}
        </Typography>
        <Typography
          className="text-[12px]"
          sx={{ color: componentColors.surface.onSurface }}
        >
          {injury.notes}
        </Typography>
      </div>
      <AppIconButton
        onClick={() => {
          void deleteInjuryHandler();
        }}
        disabled={loading}
      >
        <DeleteOutline
          sx={{
            color: componentColors.error.error,
            opacity: loading ? 0.5 : 1,
          }}
        />
      </AppIconButton>
    </Stack>
  );
};
